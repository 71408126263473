import React from 'react';
import Divider from './Divider';
import Employment from './Employment';

import './Experience.css';

const Experience = ({ experience }) => (
    <div className="experience margin-top-3">
        <h1>
            Experience 
        </h1>
        <Divider />
        { experience.map((exp, key) => (
            <Employment key={key} employment={exp} />
        ))}
    </div>
);

export default Experience;
