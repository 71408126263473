import React from 'react';
import Academic from './Academic';
import Community from './Community';
import Experience from './Experience';
import Personal from './Personal';
import ProfilePicture from './ProfilePicture';
import Publications from './Publications';
import Summary from './Summary';

import './Cv.css'
import '@fortawesome/fontawesome-free/css/all.css';

const Cv = ({ data }) => (
    <div className="cv">
        <div className="column-left">
            <ProfilePicture />
            <Summary summary={ data.summary } />
            <Academic academic={ data.academic } />
            <Publications publications={ data.publications } />
            <Community community={ data.community } />
        </div>
        <div className="column-right">
            <Personal profile={ data.profile } />
            <Experience experience={ data.experience } />
        </div>
    </div>
);

export default Cv;