import React from 'react';

import './ProfilePicture.css'
import profilePic from '../data/profile.jpg';

const ProfilePicture = () => (
    /**/
    <div className="profile-picture-background">
        <img 
            className="profile-picture"
            src={profilePic}
            alt="" />
    </div>
)

export default ProfilePicture