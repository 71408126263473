import React from 'react';

import Divider from './Divider';

import './Publications';

const Publications = ({ publications }) => (
    <div className="community margin-top-3">
        <h3> <i className="fas fa-scroll header-icon" /> Publications </h3>
        <Divider />
        { publications.map((pub, key) => (
            <div className="publication" key={key}>
                <h4>
                    { pub.title}
                </h4>       
                <div className="italic margin-top-1">
                    { 
                        pub.authors.join(", ")
                    }
                </div>
                <div>
                    { `${pub.journal}, ${pub.volume}, ${pub.date}` }
                </div> 
            </div>
        ))
        }
    </div>
);

export default Publications;