import React from 'react';

import './Degree.css';

const Degree = ({ degree }) => (
    <div className="margin-top-1">
        <h4>
            { degree.degree }, { degree.field }
        </h4>
        <div>
            { degree.start } &nbsp; - &nbsp; { degree.end }
        </div>
        <div> { degree.university } </div>
    </div>
)

export default Degree;