import React from 'react';

import Divider from './Divider';

import './Summary.css';

const Summary = ({ summary }) => (
    <div className="summary">
        <h2 className="margin-top-3"> 
            Profile 
        </h2>
        <Divider />

        { summary.map((s, key) => (
            <div key={key} className="margin-bottom-1"> {s} </div>
        )) }
    </div>

);

export default Summary;