import React from 'react';

import './Assignment.css';

const Assignment = ({ assignment }) => (
    <div className="assignment"> 
        <h3 className="assignment-title font-weight-500">
            {assignment.client} 
        </h3>
        <div> 
            { assignment.description }
        </div>
    </div>
)


export default Assignment;