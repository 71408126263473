import React from 'react';

import Degree from './Degree';  
import Divider from './Divider';

import './Academic.css';

const Education = ({ academic }) => (
    <div className="academic margin-top-3">
        <h3> <i className="fas fa-graduation-cap header-icon" /> Education </h3>
        <Divider />
        { academic.map((degree, key) => <Degree degree={degree} key={key} />)}
    </div>
);

export default Education;