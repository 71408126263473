import React from 'react';

import './Personal.css';

const Personal = ({ profile }) => (
    <div className="personal">
        <h1 className="header">
            { profile.name }
        </h1>
        <h3 className="subheader">
            { profile.description }
        </h3>
        <div className="margin-top-1"> {profile.phone } &nbsp; | &nbsp; {profile.email} </div>
    </div>
);

export default Personal;
