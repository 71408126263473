import React from 'react';
import { useCookies, CookiesProvider } from 'react-cookie';

import './App.css';
import Authorize from './components/Authorize';
import Cv from './components/Cv';
import data from './data/leader.json';

const PASSWORD = "$2a$08$RCrB4Q2w1Q0RKyBEVjWfaeryF7YCB3ncCBdvCOTypHE1PI3gbsKg2";

const App = () => {

    const [cookies, setCookie] = useCookies(['password']);

    const onAuth = isAuthorized => {
      if (!isAuthorized) {
        return;
      }
      setCookie("password", PASSWORD, { maxAge: 86400 }); 
    }

    return (
      <CookiesProvider>
        <div className="App">
          { 
            !!(cookies.password && cookies.password === PASSWORD) ? 
            <Cv data={data} /> : 
            <Authorize onAuth={onAuth} password={PASSWORD} /> 
          }
        </div>
      </CookiesProvider>
    );  
}

export default App;
