import React from 'react';

import Divider from './Divider';

import './Community.css'

const Community = ({ community }) => (
    <div className="community margin-top-3">
        <h3> <i className="fas fa-code header-icon" /> Community </h3>
        <Divider />
        {
            community.map((com, key) => (
                <div key={key}>
                    <h4 className="margin-top-1">
                        { com.heading }
                    </h4>
                    { com.subheading && <div className="italic">{ com.subheading }</div> }
                    <div>
                        { com.description }
                    </div>
                </div>
            ))
        }
    </div>
);

export default Community;
